import React, { Component } from "react";
import axios from 'axios';
import { Redirect, withRouter } from "react-router-dom";

class Registration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            password_confirmation: "",
            registrationErrors: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleSubmit(event) {
        // console.log("form submitted");
        const {
            email,
            password,
            password_confirmation
        } = this.state;

        //Hier müsste über eine API das Passwort abgefragt werden
        // axios.post("http://localhost:3000/registrations", {
        //     user: {
        //         email: email,
        //         password: password,
        //         password_confirmation: password_confirmation
        //     }
        // },
        //     { withCredentials: true }
        // ).then(response => {
        //     console.log("registration res", response);
        // }).catch(err => {
        //     console.log("registration error", err);
        // });
        if (email === "pu@zbw.ch") {
            event.preventDefault(); //verhindert, dass der Link bzw. die Seite neu geladen wird
            console.log(email);
            this.props.history.push("/dashboard"); //Somit wird die Seite /dashboard aufgerufen
        }
        event.preventDefault(); //verhindert, dass der Link bzw. die Seite neu geladen wird
    }

    handleChange(event) {
        console.log("handle change", event);
        this.setState({
            [event.target.name]: event.target.value
        });
        event.preventDefault();
    }
    render() {
        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        required
                    />
                    <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.handleChange}
                        required
                    />
                    <input
                        type="password"
                        name="password_confirmation"
                        placeholder="Password confirmation"
                        value={this.state.password_confirmation}
                        onChange={this.handleChange}
                        required
                    />
                    <button type="submit">Register</button>
                </form>
            </div>
        );
    }
}

export default withRouter(Registration);//Hiermit kann via history.push die nächste Seite aufgerufen werden.
