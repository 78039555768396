import React from 'react';
import logo from './logo.svg';
import Controlled from './Auth/Controlled';
import Uncontrolled from './Auth/Uncontrolled';
import { ThemeProvider } from '@material-ui/styles';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import Dashboard from './Dashboard';
import Routes from './Routes';
import Login from './Auth/Login';

import './App.css';
import Home from './Home';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path={"/"} component={Home} />
          <Route exact path={"/dashboard"} component={Dashboard} />
        </Switch>
      </BrowserRouter>
      {/* <Home /> */}

    </div>
  );
}

export default App;
