import React, { Component } from 'react';
import Registration from './Auth/Registration';

class Home extends Component {
    render() {
        return (
            <div>
                <h1>Home</h1>
                <h1>Home</h1>
                <Registration />
            </div>
        );
    }
}

export default Home;