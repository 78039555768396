import React, { Component } from "react";
import Button from '@material-ui/core/Button';

class Controlled extends Component {
    state = {
        username: '',
        isValid: false,
    };

    changeUsername = e => {
        const { value } = e.target;
        this.setState(() => ({
            username: value,
            isValid: value.length > 3,
        }));
    };

    submitForm = e => {
        e.preventDefault();
        alert(`Hallo ${this.state.username}`);
    };

    render() {
        return (
            <form method="post" onSubmit={this.submitForm}>
                <p>Dein Benutzername: {this.state.username}</p>
                <p>
                    {/* mit value kann der Inhalt des Textfeldes an React gebunden werden */}
                    <input
                        type="text"
                        name="username"
                        value={this.state.username}
                        onChange={this.changeUsername} />
                    <input type="submit" disabled={!this.state.isValid} />
                    <Button variant="contained">Hallo</Button>
                </p>
            </form>
        );
    }
}
export default Controlled;